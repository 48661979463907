
import { Component, Vue } from 'nuxt-property-decorator'

interface Feature {
  description: string
  img: string
  alt: string
}

@Component
export default class extends Vue {
  features: Feature[] = [
    {
      description: 'Мы предоставляем только актуальную информацию о штрафах из баз данных: ГИБДД, ГИС ГМП, ФССП и АМПП.',
      img: require('~/assets/img/features/actual-info.svg'),
      alt: 'Актуальная информация'
    },
    {
      description: 'Для проверки и оплаты не нужно регистрироваться.',
      img: require('~/assets/img/features/simple.svg'),
      alt: 'Простота'
    },
    {
      description: 'Сервис запоминает информацию, которую вы вводите в полях. Для поиска и оплаты в будущем не нужно набирать все данные заново.',
      img: require('~/assets/img/features/remember.svg'),
      alt: 'Сохранение данных'
    },
    {
      description: 'Зарегистрированные пользователи мгновенно получают уведомления о новых штрафах.',
      img: require('~/assets/img/features/notify.svg'),
      alt: 'Уведомления'
    },
    {
      description: 'Служба поддержки работает круглосуточно и без выходных. Специалисты отвечают на любые вопросы о штрафах ГИБДД.',
      img: require('~/assets/img/features/support.svg'),
      alt: 'Служба поддержки'
    },
    {
      description: 'У нашего сайта высокий рейтинг.',
      img: require('~/assets/img/features/rating.svg'),
      alt: 'Рейтинг'
    }
  ]
}
